import styles from './Sidebar.module.css'

export const Sidebar = (props: {
    setActiveTab: React.Dispatch<React.SetStateAction<number>>
    tabIndex: { [key: string]: string }
}) => {
    const { setActiveTab, tabIndex } = props

    return (
        <div className={styles.sidebar}>
            <div className={styles.title}>Settings</div>
            <div className={styles.button_group}>
                {Object.keys(tabIndex).map((i) => {
                    return (
                        <button key={i} onClick={() => setActiveTab(Number(i))}>
                            {tabIndex[i]}
                        </button>
                    )
                })}
            </div>
        </div>
    )
}
