import { useContext, useState } from 'react'
import styles from '../../App.module.css'
import { Context } from '../../utils/context'
import { restartBettor, updateSettings } from '../../services/api'
import { AccountProviderScraperIF } from '../accounts/types'
import { DropletStatus, ScraperStatusIF } from './types'
import Loader from 'react-spinners/BounceLoader'
import { StatusDetails } from './Scrapers'
import { RestartButton } from '../../components/RestartButton'

export const Bettors = () => {
    const { accounts, settings } = useContext(Context)
    const [isLoading, setIsLoading] = useState(!settings)

    const getScraperStatus = (scraper: ScraperStatusIF) =>
        scraper.healthchecksMissed > 0 &&
        scraper.dropletStatus === DropletStatus.ACTIVE
            ? StatusDetails.WARNING
            : scraper.dropletStatus === DropletStatus.ACTIVE ||
              scraper.dropletStatus === DropletStatus.STARTING ||
              scraper.dropletStatus === DropletStatus.STOPPING
            ? StatusDetails[scraper.dropletStatus]
            : StatusDetails.INACTIVE

    const accountProviders = accounts.flatMap(
        (a) =>
            a.accountProviders.map((ap) => ({
                ...ap,
                account: a,
            })) as AccountProviderScraperIF[]
    )

    const toggleBettors = async () => {
        if (settings) {
            setIsLoading(true)
            await updateSettings({
                bettorsActive: !settings.bettorsActive,
            })
            setIsLoading(false)
        }
    }

    return (
        <div className={styles.main}>
            <div className={styles.title_and_description}>
                <header>Bettors</header>
                <p>- Manage bettors</p>
            </div>
            <div className={styles.section}>
                <div className={styles.section_header}>
                    Bettor Management
                    {settings && (
                        <button
                            className={`${
                                settings.bettorsActive && styles.danger
                            }`}
                            onMouseDown={toggleBettors}
                            disabled={isLoading}
                        >
                            Turn {settings.bettorsActive ? 'Off' : 'On'} Bettors
                        </button>
                    )}
                </div>
                <div className={styles.section_content}>
                    {accountProviders.length > 0 ? (
                        <table className={styles.table}>
                            <thead>
                                <tr>
                                    <td>Status</td>
                                    <td>Site</td>
                                    <td>Username</td>
                                    <td>Provider</td>
                                    <td>Actions</td>
                                </tr>
                            </thead>
                            <tbody>
                                {accountProviders
                                    .sort(
                                        (a, b) =>
                                            getScraperStatus(a).priority -
                                            getScraperStatus(b).priority
                                    )
                                    .map((a) => {
                                        const status = getScraperStatus(a).value
                                        return (
                                            <tr key={a.id}>
                                                <td>
                                                    <div
                                                        className={
                                                            styles.game_status
                                                        }
                                                    >
                                                        <div
                                                            className={`${
                                                                styles.status
                                                            } ${
                                                                status ===
                                                                StatusDetails
                                                                    .ACTIVE
                                                                    .value
                                                                    ? styles.success
                                                                    : status ===
                                                                      StatusDetails
                                                                          .INACTIVE
                                                                          .value
                                                                    ? styles.danger
                                                                    : styles.warn
                                                            }`}
                                                        />
                                                        {status}
                                                    </div>
                                                </td>
                                                <td>
                                                    <a
                                                        href={a.account.website}
                                                        target="_blank"
                                                        rel="noreferrer"
                                                    >
                                                        {a.account.website}
                                                    </a>
                                                </td>
                                                <td>{a.account.username}</td>
                                                <td>{a.provider.name}</td>
                                                <td>
                                                    {status ===
                                                        StatusDetails.ACTIVE
                                                            .value &&
                                                        a.id && (
                                                            <RestartButton
                                                                mouseDown={() =>
                                                                    restartBettor(
                                                                        a.id!.toString()
                                                                    )
                                                                }
                                                            />
                                                        )}
                                                </td>
                                            </tr>
                                        )
                                    })}
                            </tbody>
                        </table>
                    ) : isLoading ? (
                        <div className={styles.loading}>
                            <Loader
                                loading={isLoading}
                                size={48}
                                color={'var(--dark1)'}
                            />
                        </div>
                    ) : (
                        <p>No bettors active.</p>
                    )}
                </div>
            </div>
        </div>
    )
}
