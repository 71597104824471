import React from 'react'
import styles from '../../App.module.css'
import {
    getLineAdjustment,
    getMaxAccountsPerBet,
    setLineAdjustment,
    setMaxAccountsPerBet,
} from '../../services/api'
import { PROVIDER_MAPPING } from '../accounts/helpers'

export const User = () => {
    const [lineAdjustmentAmount, setLineAdjustmentAmount] = React.useState<
        number | undefined
    >(undefined)

    const [maxAccountsPerBetData, setMaxAccountsPerBetData] = React.useState<
        | {
              [key: string]: number
          }
        | undefined
    >(undefined)

    const [disableButtons, setDisableButtons] = React.useState<boolean>(false)

    React.useEffect(() => {
        const fetchData = async () => {
            const line = await getLineAdjustment()
            setLineAdjustmentAmount(line)
            const maxAccountsPerBet = await getMaxAccountsPerBet()
            setMaxAccountsPerBetData(maxAccountsPerBet)
        }
        fetchData()
    }, [])

    return (
        <div className={styles.main}>
            <div className={styles.title_and_description}>
                <header>User</header>
                <p>- User specific settings</p>
            </div>
            <div className={styles.section}>
                <div className={styles.section_content}>
                    <div className={styles.user_setting}>
                        <div style={{ display: 'flex', width: '100%' }}>
                            <p>Line Adjustment</p>
                            <input
                                defaultValue={lineAdjustmentAmount}
                                onChange={(e) => {
                                    if (!Number(e.target.value)) return
                                    setLineAdjustmentAmount(
                                        Number(e.target.value)
                                    )
                                }}
                                style={{ width: '20%', marginLeft: 'auto' }}
                            />
                        </div>
                        <button
                            className={styles.flat}
                            disabled={disableButtons}
                            onClick={() => {
                                setDisableButtons(true)
                                setLineAdjustment(Number(lineAdjustmentAmount))
                                setTimeout(() => setDisableButtons(false), 1000)
                            }}
                        >
                            Save
                        </button>
                    </div>
                    <div className={styles.user_setting}>
                        <p>Max Accounts Per Bet</p>
                        {Object.keys(PROVIDER_MAPPING).map((provider) => {
                            return (
                                <div key={provider} style={{ display: 'flex' }}>
                                    <p>{PROVIDER_MAPPING[Number(provider)]}</p>
                                    <input
                                        style={{
                                            width: '20%',
                                            marginLeft: 'auto',
                                        }}
                                        defaultValue={
                                            maxAccountsPerBetData?.[provider]
                                        }
                                        onChange={(e) => {
                                            const newData =
                                                maxAccountsPerBetData
                                            if (!newData) return
                                            if (!Number(e.target.value)) return
                                            newData[provider] = Number(
                                                e.target.value
                                            )
                                        }}
                                    />
                                </div>
                            )
                        })}
                        <button
                            className={styles.flat}
                            disabled={disableButtons}
                            onClick={() => {
                                if (!maxAccountsPerBetData) return
                                setDisableButtons(true)
                                setMaxAccountsPerBet(maxAccountsPerBetData)
                                setTimeout(() => setDisableButtons(false), 1000)
                            }}
                        >
                            Save
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}
