import React from 'react'
import styles from '../../App.module.css'
import { PROVIDERS } from '../accounts/helpers'
import {
    getAllLeagues,
    getAllOdds,
    getDefaultLeagues,
    getDefaultOdds,
    getDisabledProviders,
    setAllLeagues,
    setAllOdds,
    setDisabledProviders,
} from '../../services/api'
import { WarningModal } from './WarningModal'

export const General = () => {
    const [disabledProvidersData, setDisabledProvidersData] = React.useState<
        string[] | undefined
    >(undefined)
    const [disabledProvidersModal, setDisabledProvidersModal] =
        React.useState<boolean>(false)
    const [oddsData, setOddsData] = React.useState<string | undefined>(
        undefined
    )
    const [oddsModal, setOddsModal] = React.useState<boolean>(false)
    const [leaguesData, setLeaguesData] = React.useState<string | undefined>(
        undefined
    )
    const [leaguesModal, setLeaguesModal] = React.useState<boolean>(false)
    const [disableButtons, setDisableButtons] = React.useState<boolean>(false)
    React.useEffect(() => {
        const fetchData = async () => {
            const disabledProviders = await getDisabledProviders()
            setDisabledProvidersData(disabledProviders?.split(','))
            const odds = await getAllOdds()
            setOddsData(odds)
            const leagues = await getAllLeagues()
            setLeaguesData(leagues)
        }
        fetchData()
    }, [])

    const onDefaultLeagues = async () => {
        const leagues = await getDefaultLeagues()
        if (!leagues) return
        setLeaguesData(JSON.stringify(JSON.parse(leagues), null, 2))
    }

    const onDefaultOdds = async () => {
        const odds = await getDefaultOdds()
        if (!odds) return
        setOddsData(JSON.stringify(JSON.parse(odds), null, 2))
    }

    return (
        <div className={styles.main}>
            <div className={styles.title_and_description}>
                <header>General</header>
                <p>- System wide settings that will affect all users</p>
            </div>
            <div className={styles.section}>
                <div className={styles.section_header}>Providers Enabled</div>
                {disabledProvidersData && (
                    <div className={styles.section_content}>
                        <div className={styles.table_container}>
                            <table className={styles.table}>
                                <tbody className={styles.scrollable}>
                                    {PROVIDERS.map((provider) => (
                                        <tr key={provider}>
                                            <td>
                                                <input
                                                    type="checkbox"
                                                    defaultChecked={
                                                        !disabledProvidersData.includes(
                                                            provider
                                                        )
                                                    }
                                                    onChange={(e) => {
                                                        setDisabledProvidersData(
                                                            e.target.checked
                                                                ? disabledProvidersData.filter(
                                                                      (x) =>
                                                                          x !==
                                                                          provider
                                                                  )
                                                                : [
                                                                      ...disabledProvidersData,
                                                                      provider,
                                                                  ]
                                                        )
                                                    }}
                                                />
                                            </td>
                                            <td className={styles.max}>
                                                {provider}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        <button
                            className={styles.flat}
                            disabled={disableButtons}
                            onClick={() => setDisabledProvidersModal(true)}
                        >
                            Save
                        </button>
                        {disabledProvidersModal && (
                            <WarningModal
                                onClose={() => setDisabledProvidersModal(false)}
                                message={
                                    'Are you sure you want to make these changes? These will affect all users on the bot.'
                                }
                                onConfirm={() => {
                                    setDisableButtons(true)
                                    setDisabledProviders(
                                        disabledProvidersData
                                            .filter((x) => x !== '')
                                            .join(',')
                                    )
                                    setDisabledProvidersModal(false)
                                    setTimeout(
                                        () => setDisableButtons(false),
                                        1000
                                    )
                                }}
                            />
                        )}
                    </div>
                )}
            </div>
            <div className={styles.section}>
                <div className={styles.section_header}>
                    Leagues{' '}
                    <button
                        className={styles.flat}
                        onClick={() => onDefaultLeagues()}
                    >
                        Default Settings
                    </button>
                </div>

                <div className={styles.section_content}>
                    <textarea
                        className={styles.general_textarea}
                        value={leaguesData}
                        onChange={(e) => setLeaguesData(e.target.value)}
                    />
                    <button
                        className={styles.flat}
                        disabled={disableButtons}
                        onClick={() => setLeaguesModal(true)}
                    >
                        Save
                    </button>
                    {leaguesModal && (
                        <WarningModal
                            onClose={() => setLeaguesModal(false)}
                            message={
                                'Are you sure you want to make these changes? These will affect all users on the bot.'
                            }
                            onConfirm={() => {
                                if (!leaguesData) return
                                setDisableButtons(true)
                                setAllLeagues(leaguesData)
                                setLeaguesModal(false)
                                setTimeout(() => setDisableButtons(false), 1000)
                            }}
                        />
                    )}
                </div>
            </div>
            <div className={styles.section}>
                <div className={styles.section_header}>
                    Odds{' '}
                    <button
                        className={styles.flat}
                        disabled={disableButtons}
                        onClick={() => onDefaultOdds()}
                    >
                        Default Settings
                    </button>
                </div>

                <div className={styles.section_content}>
                    <textarea
                        className={styles.general_textarea}
                        value={oddsData}
                        onChange={(e) => setOddsData(e.target.value)}
                    />
                    <button
                        className={styles.flat}
                        disabled={disableButtons}
                        onClick={() => setOddsModal(true)}
                    >
                        Save
                    </button>
                    {oddsModal && (
                        <WarningModal
                            onClose={() => setOddsModal(false)}
                            message={
                                'Are you sure you want to make these changes? These will affect all users on the bot.'
                            }
                            onConfirm={() => {
                                if (!oddsData) return
                                setDisableButtons(true)
                                setAllOdds(oddsData)
                                setOddsModal(false)
                                setTimeout(() => setDisableButtons(false), 1000)
                            }}
                        />
                    )}
                </div>
            </div>
        </div>
    )
}
