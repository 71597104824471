import { Modal } from '../../components/Modal'
import styles from '../../App.module.css'

export const WarningModal = (props: {
    onClose: () => void
    message: string
    onConfirm: React.MouseEventHandler<HTMLButtonElement> | undefined
}) => {
    const { onClose, onConfirm, message } = props

    return (
        <Modal onClose={onClose}>
            <div className={styles.modal}>
                <p>{message}</p>
                <div className={styles.modal_button_row}>
                    <button onClick={onClose} type="button">
                        Cancel
                    </button>
                    <button
                        className={styles.danger}
                        onClick={onConfirm}
                        type="submit"
                    >
                        Confirm
                    </button>
                </div>
            </div>
        </Modal>
    )
}
