import React from 'react'
import { Sidebar } from './Sidebar'
import styles from './Settings.module.css'
import { Games } from './Games'
import { Scrapers } from './Scrapers'
import { User } from './User'
import { General } from './General'
import { Bettors } from './Bettors'
import { Providers } from './Providers'

const tabIndex: { [key: number]: string } = {
    0: 'General',
    1: 'User',
    2: 'Providers',
    3: 'Scrapers',
    4: 'Bettors',
    5: 'Games',
}

export const Settings = () => {
    const [currentTab, setCurrentTab] = React.useState<number>(0)

    return (
        <div className={styles.main}>
            <Sidebar setActiveTab={setCurrentTab} tabIndex={tabIndex} />
            {currentTab === 0 && <General />}
            {currentTab === 1 && <User />}
            {currentTab === 2 && <Providers />}
            {currentTab === 3 && <Scrapers />}
            {currentTab === 4 && <Bettors />}
            {currentTab === 5 && <Games />}
        </div>
    )
}
