import { AccountWithMetadataIF } from './types'

export const SUPPORTED_BET_TYPES = ['Moneyline', 'Spread', 'Total']
export const SUPPORTED_BET_INTERVALS = ['Game', 'Half', 'Segment']
export const SUPPORTED_BET_LEAGUES = ['WNBA', 'CFL', 'NFL', 'CFB', 'NBA', 'CBB']
export const PROVIDERS = [
    'plive',
    'pezlive',
    'ultra',
    'dynamic',
    'metallic',
    'cris',
    'ghetto',
]
export const PROVIDER_MAPPING: { [key: number]: string } = {
    1: 'plive',
    3: 'dynamic',
    4: 'ultra',
    5: 'pezlive',
    6: 'metallic',
    7: 'cris',
    8: 'ghetto',
}

export const supportedProxyCities = [
    'Brooklyn',
    'Queens',
    'Dallas',
    'Philadelphia',
    'Las Vegas',
    'Phoenix',
    'San Antonio',
    'Atlanta',
    'Orlando',
    'Seattle',
    'Jacksonville',
    'Columbus',
    'Denver',
    'Charlotte',
    'The Bronx',
    'Portland',
    'Tampa',
    'Sacramento',
    'San Diego',
    'Indianapolis',
    'Austin',
    'Fort Worth',
    'Springfield',
    'San Jose',
    'Minneapolis',
    'Washington',
    'Newark',
    'Detroit',
    'Louisville',
    'Richmond',
    'Cleveland',
    'St Louis',
    'Pittsburgh',
    'Tacoma',
    'Arlington',
    'Kansas City',
    'Oklahoma City',
    'Milwaukee',
    'Columbia',
    'Tucson',
    'Colorado Springs',
    'Buffalo',
    'Lancaster',
    'Omaha',
    'Cincinnati',
    'Katy',
    'Rochester',
    'Albuquerque',
    'San Francisco',
    'Salt Lake City',
    'Lexington',
    'Nashville',
    'Fayetteville',
    'Fresno',
    'Aurora',
    'Virginia Beach',
    'Bakersfield',
    'Birmingham',
    'El Paso',
    'Baltimore',
    'Boston',
    'Saint Paul',
    'Memphis',
    'Staten Island',
    'Riverside',
    'Fort Lauderdale',
    'Hialeah',
    'Henderson',
    'Dayton',
    'Greenville',
    'Tappahannock',
    'Greensboro',
    'Kissimmee',
    'Spokane',
    'Spring',
    'Mesa',
    'Wichita',
    'Raleigh',
    'Wilmington',
    'Lincoln',
    'Oakland',
    'Knoxville',
    'Gainesville',
    'Lafayette',
    'Vancouver',
    'Glendale',
    'Hollywood',
    'Jackson',
    'Stockton',
    'Long Beach',
    'Tulsa',
    'Silver Spring',
    'Grand Rapids',
    'Lawrenceville',
    'Providence',
    'Albany',
    'Madison',
    'Concord',
    'Fort Wayne',
    'Honolulu',
    'Fontana',
    'New Orleans',
    'Charleston',
    'Decatur',
    'Boise',
    'Auburn',
    'Monroe',
    'Canton',
    'Irvine',
    'Montgomery',
    'Bloomington',
    'Chandler',
    'Tallahassee',
    'Alexandria',
    'Baton Rouge',
    'Reno',
    'Lake Worth',
    'Marietta',
    'Peoria',
    'Fredericksburg',
    'Plano',
    'Akron',
    'Athens',
    'Lakeland',
    'Naples',
    'West Palm Beach',
    'Woodbridge',
    'New York',
    'Los Angeles',
    'Chicago',
    'Houston',
    'Miami',
]

export const propertiesMap: Record<keyof AccountWithMetadataIF, string> = {
    id: 'ID',
    website: 'Website',
    username: 'Username',
    password: 'Password',
    tags: 'Tags',
    status: 'Status',
    proxyCity: 'Proxy City',
    proxyState: 'Proxy State',
    autoBetAmount: 'Autobet Amount',
    winTotal: 'Win Total',
    liability: 'Liability',
    isScraperAccount: 'Scraper Account',
    source: 'Source',
    supportedBetTypes: 'Supported Bet Types',
    supportedBetIntervals: 'Supported Bet Intervals',
    supportedBetLeagues: 'Supported Bet Leagues',
    family: 'Family',
    currency: 'Currency',
    accountProviders: 'Account Providers',
    activity: 'Activity',
    subSource: 'Sub-Source',
    lastCompletedBet: 'Last Completed Bet',
    lastAttemptedBet: 'Last Attempted Bet',
    rebet: 'Rebet',
}

export const downloadCSV = (name: string, rows: string[][]) => {
    const type = 'text/csv;charset=utf-8;'
    const content = rows.map((row) => row.join(',')).join('\n')
    const blob = new Blob([content], { type: type })
    const link = document.createElement('a')
    const url = URL.createObjectURL(blob)
    link.setAttribute('href', url)
    link.setAttribute('download', `Betnet Report - ${name}.csv`)
    link.style.visibility = 'hidden'
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
}

export const formatDownload = (accounts: AccountWithMetadataIF[]) =>
    accounts.map((account) => ({
        ...account,
        lastCompletedBet: account.lastCompletedBet
            ? new Date(account.lastCompletedBet).toLocaleString()
            : '',
        lastAttemptedBet: account.lastAttemptedBet
            ? new Date(account.lastAttemptedBet).toLocaleString()
            : '',
    }))
