import React from 'react'
import styles from '../../App.module.css'
import { ProviderIF } from '../accounts/types'
import { RestartButton } from '../../components/RestartButton'
import { getProviders, restartGameScraper } from '../../services/api'

export const Providers = () => {
    const [providers, setProviders] = React.useState<ProviderIF[]>([])

    React.useEffect(() => {
        ;(async () => {
            const providers = await getProviders()
            if (providers) {
                setProviders(providers.providers)
            }
        })()
    }, [])

    return (
        <div className={styles.main}>
            <div className={styles.title_and_description}>
                <header>Providers</header>
                <p>- Manage providers</p>
            </div>
            <div className={styles.section}>
                <div className={styles.section_header}>Provider Management</div>
                <div className={styles.section_content}>
                    <table className={styles.table}>
                        <thead>
                            <tr>
                                <td>Provider</td>
                                <td>Actions</td>
                            </tr>
                        </thead>
                        <tbody>
                            {providers.map((p) => {
                                return (
                                    <tr key={p.id}>
                                        <td>{p.name}</td>
                                        <td>
                                            <div className={styles.actions}>
                                                <RestartButton
                                                    mouseDown={() =>
                                                        restartGameScraper(p.id)
                                                    }
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}
