import { BetIF } from './types'

export const getAccountName = (website: string) =>
    new RegExp(/https?:\/\/(?:www\.)?([a-zA-Z0-9-.]+)\./).exec(website)?.[1] ??
    website

export const getMaxBloodRiskAmount = (amount: number, line: number) => {
    if (line < 0) {
        return (amount * Math.abs(line)) / 100
    }
    return amount
}

export const getWinningAmount = (bet: BetIF) =>
    bet.realOddsLine
        ? bet.realOddsLine < 0
            ? bet.amount // assumes max blood risk
            : (bet.riskAmount ?? bet.amount) * (bet.realOddsLine / 100) || 0
        : 0

export const getLosingAmount = (bet: BetIF) =>
    bet.realOddsLine
        ? bet.realOddsLine > 0
            ? bet.riskAmount ?? bet.amount
            : getMaxBloodRiskAmount(bet.amount, bet.realOddsLine)
        : 0

export const formatNumber = (number: number) => {
    const formatter = new Intl.NumberFormat('en-US', {
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
    })
    return formatter.format(number)
}

export const formatTeam = (awayTeam: string, homeTeam?: string) =>
    `${awayTeam}${homeTeam ? ` @ ${homeTeam}` : ''}`
