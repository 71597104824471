import { useState, useContext, useEffect } from 'react'
import { restartScraper, updateSettings } from '../../services/api'
import { Context } from '../../utils/context'
import { DropletStatus, ScraperStatusIF } from './types'
import styles from '../../App.module.css'
import Loader from 'react-spinners/BounceLoader'
import { RestartButton } from '../../components/RestartButton'
import { formatTeam } from '../bets/helpers'

export const StatusDetails = {
    ACTIVE: { value: 'ACTIVE', priority: 1 },
    STARTING: { value: 'STARTING', priority: 2 },
    STOPPING: { value: 'STOPPING', priority: 3 },
    WARNING: { value: 'WARNING', priority: 4 },
    INACTIVE: { value: 'INACTIVE', priority: 5 },
}

export const Scrapers = () => {
    const { games, settings } = useContext(Context)

    const [isLoading, setIsLoading] = useState(!settings)

    useEffect(() => {
        if (settings) setIsLoading(false)
    }, [settings])

    const toggleScrapers = async () => {
        if (settings) {
            setIsLoading(true)
            await updateSettings({
                scrapersActive: !settings.scrapersActive,
            })
            setIsLoading(false)
        }
    }

    const getScraperStatus = (scraper: ScraperStatusIF) =>
        scraper.healthchecksMissed > 0 &&
        scraper.dropletStatus === DropletStatus.ACTIVE
            ? StatusDetails.WARNING
            : scraper.dropletStatus === DropletStatus.ACTIVE ||
              scraper.dropletStatus === DropletStatus.STARTING ||
              scraper.dropletStatus === DropletStatus.STOPPING
            ? StatusDetails[scraper.dropletStatus]
            : StatusDetails.INACTIVE

    return (
        <div className={styles.main}>
            <div className={styles.title_and_description}>
                <header>Scrapers</header>
                <p>- Manage scrapers</p>
            </div>
            <div className={styles.section}>
                <div className={styles.section_header}>
                    Scraper Management
                    {settings && (
                        <button
                            className={`${
                                settings.scrapersActive && styles.danger
                            }`}
                            onMouseDown={toggleScrapers}
                            disabled={isLoading}
                        >
                            Turn {settings.scrapersActive ? 'Off' : 'On'}{' '}
                            Scrapers
                        </button>
                    )}
                </div>
                <div className={styles.section_content}>
                    {games.length > 0 ? (
                        <table className={styles.table}>
                            <thead>
                                <tr>
                                    <td>Status</td>
                                    <td>Game</td>
                                    <td>Provider</td>
                                    <td>Actions</td>
                                </tr>
                            </thead>
                            <tbody>
                                {games
                                    .sort(
                                        (a, b) =>
                                            getScraperStatus(a).priority -
                                            getScraperStatus(b).priority
                                    )
                                    .map((g) => {
                                        const status = getScraperStatus(g).value
                                        return (
                                            <tr key={g.id}>
                                                <td>
                                                    <div
                                                        className={
                                                            styles.game_status
                                                        }
                                                    >
                                                        <div
                                                            className={`${
                                                                styles.status
                                                            } ${
                                                                status ===
                                                                StatusDetails
                                                                    .ACTIVE
                                                                    .value
                                                                    ? styles.success
                                                                    : status ===
                                                                      StatusDetails
                                                                          .INACTIVE
                                                                          .value
                                                                    ? styles.danger
                                                                    : styles.warn
                                                            }`}
                                                        />
                                                        {status}
                                                    </div>
                                                </td>
                                                <td>
                                                    {formatTeam(
                                                        g.awayTeam,
                                                        g.homeTeam
                                                    )}
                                                </td>
                                                <td>{g.provider.name}</td>
                                                <td>
                                                    <div
                                                        className={
                                                            styles.actions
                                                        }
                                                    >
                                                        {status ===
                                                            StatusDetails.ACTIVE
                                                                .value && (
                                                            <RestartButton
                                                                mouseDown={() =>
                                                                    restartScraper(
                                                                        g.id
                                                                    )
                                                                }
                                                            />
                                                        )}
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    })}
                            </tbody>
                        </table>
                    ) : isLoading ? (
                        <div className={styles.loading}>
                            <Loader
                                loading={isLoading}
                                size={48}
                                color={'var(--dark1)'}
                            />
                        </div>
                    ) : (
                        <p>No scrapers active.</p>
                    )}
                </div>
            </div>
        </div>
    )
}
